<template>
  <div class="home">
    <main>
      <h1>Kinderfeuerwehr Lunestedt</h1>
    <article class="youthmain">
      
      <h2>Allgemeine Infos zur Kinderfeuerwehr:</h2><p>
        Die Kinderfeuerwehr „Firekids Lunestedt“ wurde 2017 gegründet. Sie gehört neben der Jugendfeuerwehr 
        zum Nachwuchs der Freiwilligen Feuerwehr Lunestedt. Derzeit sind die Firekids die einzige Kinderfeuerwehr 
        der Gemeinde Beverstedt. 
        3 Mädchen und 17 Jungen im Alter von 6-10 Jahren treffen sich vierzehntägig freitags von 16:30-18:00 Uhr 
        im und am Feuerwehrhaus zum Übungsdienst. Geleitet wird die Kinderfeuerwehr von der Kinderfeuerwehrwartin 
        Priska Siedenburg, ihrer Stellvertreterin Farina Siedenburg und einem großen Betreuerteam.
      <br></p>
      <div><button @click="isVisible = !isVisible">Bilder</button>
      <div class="bild_parent" v-if="isVisible">
        <div v-for="item in allgemein" :key="item" class="bilder">
          <img :src="require(`@/components/images/kinder/allgemeines/${item.url}`)" :alt="item.name"/>
        </div>
      </div> 
      </div> 
      
      <br>
      <h2>Was macht die Kinderfeuerwehr</h2>
      <p>Bei den Übungsdiensten steht Spiel, Spaß und Freude an der feuerwehrtechnischen Ausbildung 
        im Vordergrund. 
        Wie löscht man verschiedene Brände, wie schützt man sich beim Einsatz, 
        welche Geräte werden wofür benötigt, welche Fahrzeuge gibt es und wie legt man einen Verband an. 
        Dies und vieles mehr, was man später als Feuerwehrfrau / -mann wissen muss, lernen die KF-Mitglieder. 
        Aber auch Ausflüge, gemeinsame Aktionen mit der Jugendfeuerwehr stehen ebenfalls auf dem Dienstplan.
      <br></p>
      <div><button @click="isVisible1 = !isVisible1">Bilder</button>
      <div class="bild_parent" v-if="isVisible1">
        <div v-for="item in was" :key="item" class="bilder">
          <img :src="require(`@/components/images/kinder/was/${item.url}`)" :alt="item.name"/>
        </div>
      </div> 
      </div>

      <br>
      <h2>Abzeichen der Kinderfeuerwehr</h2>
      <p>Das gelernte Feuerwehrwissen kann bei Prüfungen gezeigt werden. 
        Zu den Prüfungen gehört die Kinderflamme Stufe 1 und 2  und der Brandfloh. 
        Bei der Kinderflamme Stufe 1 und 2 zeigen die KF Mitglieder in Kleingruppen ihr theoretisches und 
        praktisches Wissen. Sie müssen z.B. verschiedene Feuerwehrfahrzeuge benennen und erklären, eine Karte 
        und deren Legende erläutern, den Notruf absetzen können und den richtigen Umgang mit der Fackel 
        präsentieren. Des Weiteren gehört auch ein Erste Hilfe Anteil dazu. 
        Der Brandfloh ist die höchste Auszeichnung in der Kinderfeuerwehr und kann ab 9 Jahren absolviert werden. 
        Die Prüfung besteht aus mehreren Aufgaben, für die wir gemeinsam üben. 
      <br></p>
      <div><button @click="isVisible2 = !isVisible2">Bilder</button>
      <div class="bild_parent" v-if="isVisible2">
        <div v-for="item in wettbewerbe" :key="item" class="bilder">
          <img :src="require(`@/components/images/kinder/wettbewerbe/${item.url}`)" :alt="item.name"/>
        </div>
      </div> 
      </div> 
      
      <br>
      <h2>Lager, Fahrten und Aktionen der Kinderfeuerwehr</h2>
      <p>Zu den besonderen Highlights gehören die Zeltlager. 
        Alle 3 Jahre findet das große Kreiszeltlager mit ca. 250 Teilnehmern statt. 
        Ein Wochenende voller Spiel und Spaß mit allen Kinderfeuerwehren aus dem Landkreis Cuxhaven. 
        Aber auch das kleinere Gemeindezeltlager mit den Jugendfeuerwehren aus der Gemeinde Beverstedt ist 
        sehr beliebt. 
        (Foto Zeltlager von oben) 
        Weitere Aktionen wie der Kreiskinotag, Übernachtungen im Feuerwehrhaus, Ausflüge zur Berufsfeuerwehr 
        und Polizei, Besuch eines Freizeitparks, Bastelaktionen oder die Teilnahme am 
        Lunestedter Umwelttag stärken das Wir-Gefühl.
        <br></p>
      <div><button @click="isVisible3 = !isVisible3">Bilder</button>
      <div class="bild_parent" v-if="isVisible3">
        <div v-for="item in freizeit" :key="item" class="bilder">
          <img :src="require(`@/components/images/kinder/freizeit/${item.url}`)" :alt="item.name"/>
        </div>
      </div> 
      </div> 
    
      <br>
      <h3>Kontakte</h3>
      <p>Priska Siedenburg, Kinderfeuerwehrwartin</p>
      <p>Ina Gerdes, stellv. Kinderfeuerwehrwartin</p>
      <P>E-Mail: &#70;&#105;&#114;&#101;&#107;&#105;&#100;&#115;&#64;&#102;&#101;&#117;&#101;&#114;&#119;&#101;&#104;&#114;&#45;&#108;&#117;&#110;&#101;&#115;&#116;&#101;&#100;&#116;&#46;&#100;&#101;</P>
    </article>
</main>
  </div>
</template>

<script>

export default {
  name: 'KinderFeuer',
  data() {
    return {
      isVisible: false,
      isVisible1: false,
      isVisible2: false,
      isVisible3: false,
      //Hier Bilder allgemein zur KF (Lunestedt) einfügen
      allgemein: [
        {url: 'gruppenfoto.jpg', name: 'Gruppenfoto der Kinderfeuerwehr'},
        {url: 'gruppenfotokfjf.jpg', name: 'Gruppenfoto mit der Kinderfeuerwehr und Jugendfeuerwehr'},
        {url: 'eroeffnung.jpg', name: 'Eröffnung des Feuerwehrhauses'},
      ],
      //Hier Bilder zum Übungsdienst und was die KF macht einfügen
      was: [
        {url: 'uebungsdienst.jpg', name: 'Übungsdienst mit einer Wärmebildkamera'},
        {url: 'uebungsdienstwasser.jpg', name: 'Übungsdienst Wasserspiele'},
        {url: 'uebungsdienstwasser1.jpg', name: 'Übungsdienst Wasserspiele'},
        {url: 'spiele.jpg', name: 'Jenga Spielen beim Übungsdienst'},
      ],
      //Hier Bilder zu den Wettbewerben einfügen
      wettbewerbe: [
        {url: 'kinderflamme.jpg', name: 'Grppenfoto nach der Kinderflamme'},

      ],
      //Hier Bilder zur Freizeit Aktivitäten sowie Lager und Fahrten einfügen
      freizeit: [
        {url: 'polizeifahrt.jpg', name: 'Besuch bei der Polizei'},
        {url: 'weihnachten.jpg', name: 'Weihnachtsübungsdienst bei der Kinderfeuerwehr'},

      ],
    }
  },
  components: {
  },
  methodes: {
  }
}
</script>
<style scoped>
.youthmain {
  margin-top: 20px;
}
.bild_parent {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border: solid 1px;
  border-radius: 5px;
  border-color: var(--inverted-color);
  padding-bottom: 15px;
  margin-top: 10px;
}
.bilder {
  width: 20%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 15px;
}
img {
  max-width: 100%;
  height: auto;
}
button {
  border-radius: 5px;
  border-color: var(--inverted-color);
  background-color: transparent;
  font-size: 20px;
  color: var(--inverted-color);
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: 0.3s;
}
button:hover {
  color: var(--complementary-color);
  transition: 0.3s;
}
h2 {
  color: var(--primary-color);
  margin-top: 50px;
}
h3 {
  margin-top: 50px;
}
</style>

